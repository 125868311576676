
<template id="mojarico">
  <product title="Mojarico"
  >
    <template v-slot:image>
      <slot>
        <img src="@/assets/mojari.jpg"


        >
      </slot>
    </template>
  </product>
</template>

<!--<product >
<template v-slot:image>
  <slot>
    <img src="src/assets/mojari.jpg">
  </slot>
</template>
</product>-->



<script>


import product from "@/components/partials/PageTransitions/PageTransitionsNr1/components/product";

/*import image from "@"*/

export default {
  name: "mojarico",


  components: {
    product
  },

  data() {


    return {

    }

  },



}
</script>

<style scoped>

</style>
